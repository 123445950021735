import clsx from 'clsx';
import React, {
    ButtonHTMLAttributes,
    ComponentProps,
    ForwardedRef,
    forwardRef,
    PropsWithChildren,
    ReactElement,
    ReactNode,
} from 'react';
import { Link } from "@remix-run/react";

interface Props {
    color?: 'default' | 'primary' | 'secondary' | 'danger';
    icon?: ReactNode;
    className?: string;
}

function getClasses(props: Props): string {
    const { color = 'default', className } = props;

    return clsx(
        'p-2 rounded-md text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:z-index-1 space-x-2',
        color === 'default' &&
            'border bg-white text-black hover:text-black border-gray-300 hover:bg-gray-100 active:bg-gray-100 focus:ring-gray-300',
        color === 'primary' &&
            'bg-blue-600 text-white hover:text-white hover:bg-blue-800 active:bg-blue-800 focus:ring-blue-500',
        color === 'secondary' &&
            'bg-blue-600 text-white hover:text-white hover:bg-blue-800 active:bg-blue-800 focus:ring-blue-500',
        color === 'danger' &&
            'bg-red-600 text-white hover:text-white hover:bg-red700 active:bg-red-700 focus:ring-red-500',
        className,
    );
}

function ButtonIcon(props: Props): ReactElement | null {
    if (!props.icon) return null;

    return <span className="inline-block w-5 h-5 align-middle">{props.icon}</span>;
}

function Button(
    props: PropsWithChildren<Props & ButtonHTMLAttributes<HTMLButtonElement>>,
    ref: ForwardedRef<HTMLButtonElement>,
): ReactElement {
    const { type = 'button', icon, children, ...rest } = props;

    return (
        <button {...rest} ref={ref} type={type} className={getClasses(props)}>
            <ButtonIcon {...props} />
            {children ? <span>{children}</span> : null}
        </button>
    );
}

function LinkButton(
    props: PropsWithChildren<Props & ComponentProps<typeof Link>>,
    ref: ForwardedRef<HTMLAnchorElement>,
): ReactElement {
    const { icon, children, ...rest } = props;
    return (
        <Link {...rest} ref={ref} className={getClasses(props)}>
            <ButtonIcon {...props} />
            {children ? <span>{children}</span> : null}
        </Link>
    );
}

export default Object.assign({}, forwardRef(Button), {
    Link: forwardRef(LinkButton),
});
